export default function radioStyle() {
	const radios = document.querySelectorAll('.radio-style')
	// console.log(radios, 'radios')

	function geraTemplateRadioLabel(div, labelAntiga) {
		return `<label class="check-label">${div.innerHTML} <span class="radio-circle">
					<i class="radio-circle-inner"></i>
			</span>${labelAntiga}</label>`
	}

	function itemLista(radio) {
		const label = radio.querySelector('label')
		const input = label.querySelector('input')
		input.classList.add('sr-only')

		const div = document.createElement('div')
		div.append(input)
		radio.innerHTML = geraTemplateRadioLabel(div, label.innerText)
	}
	radios.forEach((radio) => {
		itemLista(radio)
	})
}
